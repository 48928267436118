

import {IonContent, IonIcon, IonInput, IonItem, IonPage, IonRadio, IonRadioGroup} from '@ionic/vue';
import { ref, computed } from 'vue';
import { store } from '@/store/Store';
import {useRoute, useRouter} from "vue-router";
import FixedFooter from '@/components/FixedFooter.vue'
import { caretDownOutline, closeOutline } from "ionicons/icons";
import { Partner } from "@/models/PartnerModel";
import { openSelectModal } from '@/services/SelectModalService';
import { GET, PUT } from "@/services/HTTP";
import { SelectOption } from "@/models/SelectOptionModel";
import {presentNativeAlert, presentValidationAlert} from "@/services/Notify";
import {execute, executeWithoutSpinner} from "@/mixins/LoadingMixin";
import {Role} from "@/models/RoleModel";
import {User} from "@/models/UserModel";
import { AppInstance } from "@/models/AppInstanceModel";
import { EMPTY_GUID } from "@/utils/GuidUtil";
import {saveUser} from "@/services/UserService";
import {getCompleteAppInstances} from "@/services/AppInstanceService";
import {defineComponent} from "@vue/runtime-core";
import {useStore} from "vuex";
import {FooterButtonsBuilder} from "@/mixins/FooterButtonsBuilder";
import {getPartners} from "@/services/PartnerService";
import {isValidEmail} from "@/utils/EmailUtil";

export default defineComponent({
    name: 'EditUser',
    components: {
        IonContent,
        IonPage,
        IonItem,
        FixedFooter,
        IonInput,
        IonRadio,
        IonRadioGroup,
        IonIcon
    },
    async ionViewWillEnter() {
        await store.dispatch('app/setPageTitle', this.currentId == '0' ? this.language.addUser : this.language.editUser);
        this.currentUser = store.getters['auth/loggedInUser'];

        await execute(async () => {
            await executeWithoutSpinner(async () => {
                this.appInstances = await getCompleteAppInstances();
            }, this.language.errors.gettingAppInstances);

            if (this.currentId !== '0') {
                this.user = await GET('/users/' + this.currentId);
                if (this.user.role.name === 'Super Admin') {
                    this.user.appInstances = this.appInstances;
                } else if (this.user.role.name === 'Partner Admin') {
                    this.user.appInstances = this.filterAppInstancesByPartner();
                } else {
                    this.reloadAppInstanceSelectOptions(this.filterAppInstancesByPartner());
                }
                this.userRoleDisabledStyle = this.currentUser.permissions.includes('ViewPartnerAppInstances') && this.currentUser.id === this.user.id ? 'ion-item-disabled' : '';
                this.partnerDisabledStyle = 'ion-item-disabled';
            } else if (this.user.partner.name !== '') {
                this.reloadAppInstanceSelectOptions(this.filterAppInstancesByPartner());
            }

            const partners = await getPartners();
            this.partnersSelectOptions = partners.map((partner: Partner) => {
                return {
                    value: partner.id,
                    text: partner.name,
                    isChecked: partner.id === this.user.partner?.id
                } as SelectOption
            });
            
            let roles = await GET('/roles');
            if (this.currentUser.permissions.includes('ViewPartnerAppInstances')) {
                roles = roles.filter((role: Role) => role.name !== 'Super Admin');
            }
            this.roleSelectOptions = roles.map((role: Role) => {
                return {
                    value: role.id,
                    text: role.name,
                    isChecked: role.id === this.user.role.id
                } as SelectOption
            });
        }, this.language.errors.loadingUser);
    },
    setup() {
        const store = useStore();
        const language = computed(() => store.getters['app/language']);
        const router = useRouter();
        const route = useRoute();
        const currentId = computed(() => route.params.id || '0');
        const currentUser = ref(store.getters['auth/loggedInUser']);
        const user = ref({
            id: EMPTY_GUID,
            username: '',
            email: '',
            active: true,
            archived: false,
            partner: {
                id: currentUser.value.partner.id,
                name: currentUser.value.partner.name
            } as Partner,
            role: {
                id: EMPTY_GUID,
                name: ''
            } as Role,
            appInstances: [],
            appInstanceIds: [],
            appInstancePreference: EMPTY_GUID
        } as User);
        const needPartnerSelect = computed(() => user.value.role.name !== 'Super Admin');
        const needAppInstances = computed(() => user.value.role.name === '' || user.value.role.name === 'Creator');
        const partnersSelectOptions = ref([] as SelectOption[]);
        const roleSelectOptions = ref([] as SelectOption[]);
        const aiSelectOptions = ref([] as SelectOption[]);
        const appInstances = ref([] as AppInstance[]);
        const userRoleDisabledStyle = ref (currentUser.value.permissions.includes('ViewPartnerAppInstances') && currentUser.value.id === user.value.id ? 'ion-item-disabled' : '');
        const partnerDisabledStyle = ref(currentUser.value.permissions.includes('ViewPartnerAppInstances') || user.value.role.name === '' ? 'ion-item-disabled' : '');
        const userStatusOptions = computed(() => [{
            value: language.value.archived,
            text: language.value.archived,
            isChecked: user.value.archived
        }, {
            value: language.value.active,
            text: language.value.active,
            isChecked: !user.value.archived
        }]);
            
        const filterAppInstancesByPartner = (): AppInstance[] => {
            return appInstances.value.filter((instance: AppInstance) => instance.partnerId === user.value.partner.id);    
        }
        
        const reloadAppInstanceSelectOptions = (instances: AppInstance[]) => {
            aiSelectOptions.value = instances.map((instance: AppInstance) => {
                return {
                    value: instance.id,
                    text: instance.name,
                    isChecked: user.value.appInstances.some((inst: AppInstance) => inst.id === instance.id)
                } as SelectOption
            });
        }

        const changePreference = (e: any) => {
            user.value.appInstancePreference = e.target.value;
        }

        const pickPartner = async () => {
            const partnersSelect = await openSelectModal('Partners', 'radio', partnersSelectOptions.value, false);
            partnersSelect.onWillDismiss().then(({ data }: any) => {
                partnersSelectOptions.value = data;
                user.value.partner.name = data.find((option: SelectOption) => option.isChecked)?.text || '';
                user.value.partner.id = data.find((option: SelectOption) => option.isChecked)?.value;
                
                if (user.value.role.name === 'Partner Admin') {
                    user.value.appInstances = filterAppInstancesByPartner();
                    user.value.appInstancePreference = user.value.appInstances.length === 0 ? EMPTY_GUID : user.value.appInstances[0].id;
                } else {
                    user.value.appInstances = [];
                    const filteredInstances = filterAppInstancesByPartner();
                    reloadAppInstanceSelectOptions(filteredInstances);
                }
            });
        }

        const pickStatus = async () => {
            const statusSelect = await openSelectModal(language.value.userStatus, 'radio', userStatusOptions.value, false);
            statusSelect.onWillDismiss().then(({data}: any) => {
                user.value.archived = data.find((option: SelectOption) => option.isChecked)?.value === language.value.archived;
            });
        }

        const pickRole = async () => {
            const roleSelect = await openSelectModal(language.value.roles, 'radio', roleSelectOptions.value, false);
            roleSelect.onWillDismiss().then(({data}: any) => {
                roleSelectOptions.value = data;
                user.value.role.name = data.find((option: SelectOption) => option.isChecked)?.text;
                user.value.role.id = data.find((option: SelectOption) => option.isChecked)?.value;
                partnerDisabledStyle.value = user.value.partner.id === EMPTY_GUID ? '' : 'ion-item-disabled';
                if (user.value.role.name === 'Super Admin') {
                    partnersSelectOptions.value.map((option: SelectOption) => option.isChecked = false);
                    user.value.appInstances = appInstances.value;
                    user.value.appInstancePreference = user.value.appInstances.some((instance: AppInstance) => user.value.appInstancePreference === instance.id) ? user.value.appInstancePreference : user.value.appInstances[0].id;
                } else if (user.value.role.name === 'Partner Admin') {
                    user.value.appInstances = user.value.partner.name === '' ? appInstances.value : filterAppInstancesByPartner();
                    user.value.appInstancePreference = user.value.appInstances.length === 0 ? EMPTY_GUID : user.value.appInstances[0].id;
                } else {
                    user.value.appInstances = [];
                    if (user.value.partner.name !== '') {
                        const filteredInstances = filterAppInstancesByPartner();
                        reloadAppInstanceSelectOptions(filteredInstances);
                    }
                }
            });
        }
        
        const pickAppInstances = async () => {
            const aiSelect = await openSelectModal('App Instances', 'checkbox', aiSelectOptions.value, false);
            aiSelect.onWillDismiss().then(({ data }: any) => {
                aiSelectOptions.value = data;
                user.value.appInstances = data.filter((instance: SelectOption) => instance.isChecked).map((instance: SelectOption) => ({
                    id: instance.value,
                    name: instance.text
                } as AppInstance));
                if (user.value.appInstances.length > 0) {
                    user.value.appInstancePreference = user.value.appInstances.some((instance: AppInstance) => user.value.appInstancePreference === instance.id) ? user.value.appInstancePreference : user.value.appInstances[0].id;
                }
            });
        }

        const removeAppInstance = (id: string) => {
            user.value.appInstances = user.value.appInstances.filter((instance: AppInstance) => instance.id !== id);
            aiSelectOptions.value.map((instance: SelectOption) => instance.isChecked = instance.value === id ? false : instance.isChecked);
            if (user.value.appInstances.length === 1 || (user.value.appInstancePreference === id && user.value.appInstances.length > 0)) {
                user.value.appInstancePreference = user.value.appInstances[0].id;
            }
        }
        
        const resetPassword = async () => {
            await execute(async () => {
                await PUT('/reset-password', { usernameOrEmail: user.value.username });
                await presentNativeAlert({
                    cssClass: 'custom-alert',
                    header: language.value.passwordEmailed,
                    message: language.value.passwordResetLink,
                    buttons: [language.value.ok.toUpperCase()],
                });
            }, language.value.errors.resettingPassword);
        }
        
        const submitForm = async () => {
            if (user.value.username.trim() === '') {
                presentValidationAlert(language.value.validation.enterUsername);
            } else if (user.value.username.trim().length < 6) {
                presentValidationAlert(language.value.validation.usernameAtLeast6);
            } else if (user.value.email.trim() === '') {
                presentValidationAlert(language.value.validation.enterEmail);
            } else if (!isValidEmail(user.value.email.trim())) {
                presentValidationAlert(language.value.validation.emailFormat);
            } else if (user.value.role.id === EMPTY_GUID) {
                presentValidationAlert(language.value.validation.selectRole);
            } else if (needPartnerSelect.value && user.value.partner.id === EMPTY_GUID) {
                presentValidationAlert(language.value.validation.selectPartner);
            } else if (user.value.appInstances.length === 0) {
                presentValidationAlert(language.value.validation.assignAI);
            } else {
                await execute(async () => {
                    const data = await saveUser(user.value);
                    if (data) {
                        if (currentUser.value.id === user.value.id) {
                            await store.dispatch('auth/logout');
                            await router.push({name: 'login'});
                        } else {
                            user.value = {
                                id: EMPTY_GUID,
                                username: '',
                                email: '',
                                active: true,
                                archived: false,
                                partner: {
                                    id: EMPTY_GUID,
                                    name: ''
                                } as Partner,
                                role: {
                                    id: EMPTY_GUID,
                                    name: ''
                                } as Role,
                                appInstances: [],
                                appInstanceIds: [],
                                appInstancePreference: EMPTY_GUID
                            };
                            router.push({name: 'users'});
                        }
                    }
                }, language.value.errors.savingUser);
            }
        }

        return {
            store,
            language,
            user,
            currentId,
            footerButtons: computed(() =>
                new FooterButtonsBuilder()
                    .addClearButton(false, () => router.back(), language.value.cancel)
                    .addPrimaryButton(false, () => submitForm(), currentId.value === '0' ? language.value.addUser : language.value.saveEdits)
                    .create()
            ),
            caretDownOutline,
            closeOutline,
            openSelectModal,
            partnersSelectOptions,
            aiSelectOptions,
            pickPartner,
            roleSelectOptions,
            pickAppInstances,
            pickRole,
            pickStatus,
            changePreference,
            removeAppInstance,
            resetPassword,
            needPartnerSelect,
            needAppInstances,
            appInstances,
            reloadAppInstanceSelectOptions,
            userRoleDisabledStyle,
            partnerDisabledStyle,
            filterAppInstancesByPartner,
            currentUser,
            appInstancesDisabled: computed(() => user.value.partner.name === '')
        }
    }
});
